<template>
  <div class="upload">
    <!--添加模型-->
    <Modal v-model="addModelShou"
           footer-hide
           draggable
           @on-cancel="closeAddModel"
           :mask-closable="false">
      <p slot="header"
         style="text-align: center">{{$store.state.uploadType==1?'模型上传':'正射上传'}}</p>
      <div>
        <div style="display: flex; justify-content: space-between">
          <div>
            <p class="title">
              <span style="margin-left:20px;font-size:14px">成果名称：</span>
            </p>
            <Input v-model.trim="modelname"
                   placeholder="必填"
                   maxlength="15"
                   show-word-limit></Input>
          </div>
        </div>

        <div v-if="clearUp"
             id="upload-container"
             @click="start()">
          <span>点击选择文件</span>
        </div>
        <p v-else
           style="text-align: center; margin-top: 10px">
          正在清除缓存文件,请稍后...
        </p>
      </div>
    </Modal>
    <!-- 进度条 -->
    <!-- <div v-if="percent>0"
         class="progress">
      <Progress :percent="percent"
                :stroke-width="25"
                style="width:500px"
                status="active"
                text-inside />
    </div> -->
    <div id="upload-list"></div>
    <div id="picker"
         style="display: none"></div>
  </div>
</template>
<script>
import Api from '../utils/api.js';

export default {
  name: 'vue-upload',
  data() {
    return {
      uploader: null,
      addModelShou: false,
      modelname: '',
      percent: 0,
      token: '',
      userInfo: {},
      fileSize: 0,
      fileName: '',
      clearUp: false,
    };
  },
  mounted() {
    this.token = sessionStorage.getItem('token');
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    let _this = this;
    this.$Loading.config({
      color: '#5cb85c',
      failedColor: '#f0ad4e',
      height: 8,
    });
    this.$_bus.$off('addModalshow').$on('addModalshow', function () {
      if (window.loadlock) {
        this.$Message.warning('请先等待当前文件上传完成');
        return false;
      }
      _this.addModelShou = true;
      _this.modelname = '';
      _this.$post(Api.uploadClear()).then((res) => {
        if (res.code == 1) {
          _this.clearUp = true;
          if (_this.uploader) {
            _this.uploader.reset();
          } else {
            _this.initWebUpload();
          }
        } else {
          _this.$Message.error(res.msg_customer);
        }
      });
    });
    window.loadlock = false;
    // 监听页面关闭
    // window.addEventListener('beforeunload', (e) => {
    //   alert('文件正在上传,请勿关闭窗口');
    //   //   if (this.loadlock) {
    //   // this.$Message.warning('文件正在上传,请勿关闭窗口');
    //   return '文件正在上传,请勿关闭窗口';
    //   //   }
    // });
    window.onbeforeunload = function (e) {
      if (window.loadlock) {
        return '文件正在上传,请勿关闭窗口';
      }
    };
  },
  methods: {
    start() {
      if (this.modelname == '') {
        this.$Message.warning('请先填写成果名称');
        return false;
      } else {
        $('#picker').find('input').click();
      }
    },
    initWebUpload() {
      this.uploader = WebUploader.create({
        auto: false, // 选完文件后，是否自动上传
        swf: '/public/lib/Uploader.swf', // swf文件路径
        server: Api.webuploadUrl(), // 文件接收服务端
        pick: '#picker',
        accept: {
          title: '压缩包',
          extensions: 'zip, ZIP',
          mimeTypes: 'application/zip',
        },
        headers: { token: this.token },
        chunked: true, // 开启分片上传。
        chunkSize: 1024 * 1024 * 5,
        threads: 3, // 上传并发数。允许同时最大上传进程数。
        fileSizeLimit: 1024 * 1024 * 1024 * 50, //验证文件总大小是否超出限制, 超出则不允许加入队列。
      });

      // 当有文件被添加进队列的时候，添加到页面预览
      this.uploader.on('fileQueued', (file) => {
        console.log(file.size, this.$store.state.uploadResidue);
        if (file.size > this.$store.state.uploadResidue) {
          this.$Message.error('云存储空间不足');
          this.uploader.cancelFile(file);
          return false;
        } else {
          this.uploader.upload(file);
          // 选中文件时要做的事情，比如在页面中显示选中的文件并添加到文件列表，获取文件的大小，文件类型等
          this.$Message.info('文件上传开始,请勿关闭页面!');
          this.fileSize = file.size.toString();
          this.fileName = file.name;
          this.addModelShou = false;
        }
      });

      this.uploader.on('uploadStart', (file) => {
        window.loadlock = true;
        // 在这里可以准备好formData的数据
        this.$Loading.start();
        // this.$Loading.update(0);
      });

      // 文件上传过程中创建进度条实时显示。
      this.uploader.on('uploadProgress', (file, percentage) => {
        this.percent = +(percentage * 100).toFixed(2);
        this.$Loading.update(this.percent);
      });

      // 当文件上传成功时触发。
      this.uploader.on('uploadSuccess', (file, response) => {
        console.log(file.name + '传输成功');
        this.startToUnZip();
        this.$Loading.update(100);
      });

      this.uploader.on('uploadError', (file, reason) => {
        console.error(reason);
      });

      this.uploader.on('error', (type) => {
        let errorMessage = '';
        if (type === 'F_EXCEED_SIZE') {
          errorMessage = `文件大小不能超过50GB`;
        } else if (type === 'Q_EXCEED_NUM_LIMIT') {
          errorMessage = '文件上传已达到最大上限数';
        } else {
          errorMessage = `上传出错！请检查后重新上传！错误代码${type}`;
        }
        this.$Loading.finish();
        this.closeAddModel();
        console.error(errorMessage);
        this.$Message.error(errorMessage);
      });

      // 不管成功或者失败，文件上传完成时触发。
      this.uploader.on('uploadComplete', (file, response) => {
        // this.$emit('complete', file, response);
      });
    },

    // upload(file) {
    //   this.uploader.upload(file);
    // },
    // stop(file) {
    //   this.uploader.stop(file);
    // },
    // // 取消并中断文件上传
    // cancelFile(file) {
    //   this.uploader.cancelFile(file);
    // },
    // // 在队列中移除文件
    // removeFile(file, bool) {
    //   this.uploader.removeFile(file, bool);
    // },

    // 关闭添加框,清空数据
    closeAddModel() {
      this.modelname = '';
      this.percent = 0;
      this.token = '';
      this.fileSize = 0;
      this.fileName = '';
    },

    // 通知解压
    startToUnZip() {
      this.$post(Api.uploadCompress(), {
        type: 1,
        size: this.fileSize,
        file_name: this.fileName.substring(0, this.fileName.lastIndexOf('.')),
      }).then((res) => {
        this.$Loading.finish();
        this.addFile(res.data.path);
        console.log(res);
      });
    },
    // 添加文件
    addFile(path) {
      let url, params;
      if (this.$store.state.uploadType == 1) {
        url = Api.AddModel();
        params = {
          modelname: this.modelname,
          modelarea: 0,
          team_id: this.userInfo.team_id,
          lat: 0,
          lng: 0,
          file_size: parseInt(this.fileSize / 1024),
          mode_type: 1, // 模型
          file: path + '/mission.json',
        };
      } else {
        url = Api.AddYinshe();
        params = {
          mapname: this.modelname,
          team_id: this.userInfo.team_id,
          maparea: 0,
          map_data: '2021-12-07',
          mapcount: '0',
          address: '',
          angle_data: '',
          lat: 0,
          lng: 0,
          file: path,
          bf_id: 0,
          file_size: parseInt(this.fileSize / 1024),
          mode_type: 2, // 地图
        };
      }

      this.$post(url, params).then((res) => {
        window.loadlock = false;
        if (res.code === 1) {
          this.$Message.success('文件上传成功');
          // 清空数据
          //   this.closeAddModel();
          //   根据路由做通知更新
          //   if (this.$route.path == '/index/resource/modal') {
          //     console.log('123');
          //     this.$_bus.$emit('reLoad1');
          //   } else if (this.$route.path == '/index/resource/ermodal') {
          //     this.$_bus.$emit('reLoad2');
          //   }
          this.$_bus.$emit('reLoad');
        } else {
          this.closeAddModel();
          this.$Message.error('文件有错，添加失败！');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#map {
  width: 648px;
  height: 470px;
  position: relative;
  .mapSearch {
    position: absolute;
    top: 14px;
    left: 11px;
    z-index: 400;
    width: 290px;
    height: 40px;
    background: rgba(23, 23, 23, 0.71);
    border: 1px solid #454545;
    line-height: 40px;
    text-align: left;

    input {
      background: transparent;
      outline: none;
      border: 0;
      color: #fff;
    }
  }
  #addMap20a {
    width: 100%;
    height: 100%;
  }
  .tiptop {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 30;
    left: 0;
    text-align: center;
    background-color: #f19900;
    color: #000;
    height: 25px;
    line-height: 25px;
  }
}
#upload-container {
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #3b94f1;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
}
.progress {
  position: fixed;
  z-index: 1800;
  bottom: 10px;
  right: 10px;
  padding: 5px;
  background-color: red;
}
.title {
  font-size: 12px;
  width: 250px;
}
.ivu-input-wrapper {
  width: 430px;
}
.ivu-select-selection {
  background: rgb(31, 31, 31);
  border: none;
  border-radius: 0px;
}
.ivu-select-dropdown {
  background: rgb(31, 31, 31);
  border-radius: 0px;
}
.ivu-select-selected-value {
  color: #c9caca;
}
.tool-div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #777777;
  line-height: 20px;
}
</style>
