<template>
  <div class="layout" id="layout">
    <div class="header">
      <div class="header-left">
        <!-- <img :src="Logo"
             alt /> -->
        <!-- <div class="left-system-name">海南交警无人机管控系统</div>
        <div class="divide"></div>
        <div class="left-team-name">{{ nav }}</div> -->
        <!-- <Breadcrumb>
          <BreadcrumbItem v-for="(item, i) in nav"
                          :to="item.path"
                          :key="i">{{
            item.name
          }}</BreadcrumbItem>
        </Breadcrumb> -->
        <TeamListCascader
          :style="{ visibility: teamShow ? 'visible' : 'hidden' }"
          class="teamList"
        />
        <h1 class="header-policeBadge">
          <img class="header-policeBadge-img" :src="police_badge" />
          <div>海南交警无人机管控系统</div>
        </h1>
      </div>
      <div class="header-right">
        <div class="headImg" v-if="userType !== 0">
          <img
            alt="用户头像"
            :src="UserData.head_icon"
            @click="EditUserShou = !EditUserShou"
          />
        </div>
        <span>{{ UserData.name }}</span>
        <div class="header-right-exit">
          <img src="../assets/img/Icon/exit.png" alt srcset @click="exit()" />
        </div>
      </div>
    </div>

    <div class="layoutSidebar">
      <!-- <div
        v-if="this.user_info.permission != 0"
        :class="['team-cascader', teamShow ? '' : 'disable-team']"
        @click="showDrawer"
      >
        <img src="@/assets/img/Icon/side_pull.png" alt="" />
      </div> -->
      <div class="sidebar">
        <div
          :class="{ select: changeRed == ind }"
          v-for="(item, ind) in MenuList"
          :key="ind"
          @click="MenuSwitchover(item, ind)"
        >
          <div class="item">
            <img
              :src="changeRed == ind ? item.img_press : item.img"
              :alt="item.name"
            />
            <span>{{ item.name }}</span>
          </div>
          <div class="bar-child" v-if="item.children && item.children.length">
            <div
              v-for="(child, cIndex) in item.children"
              :key="cIndex"
              @click.stop="setActiveMenu(cIndex, child)"
              :class="
                activeChildMenu === cIndex && changeRed === ind ? 'active' : ''
              "
            >
              <span>{{ child.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="Content">
        <router-view :userData="userData" :websock="websock"></router-view>
      </div>
    </div>

    <!--弹窗区-->
    <Modal
      v-model="EditUserShou"
      class-name="EditUser-modal"
      @on-cancel="closeEditUser"
    >
      <p slot="header" style="text-align: center">
        <span>编辑用户</span>
      </p>
      <div style="padding: 10px 30px">
        <Form
          ref="EditUser"
          :model="EditUser"
          :rules="ruleValidate"
          label-position="top"
        >
          <FormItem label="修改用户头像 :" prop="head_icon_Edit">
            <div
              class="upload-list"
              v-for="(item, idx) in uploadList"
              :key="idx"
            >
              <template v-if="item.status === 'finished'">
                <img :src="item.path + item.url" />
                <div class="upload-list-cover">
                  <Icon
                    type="ios-trash-outline"
                    @click="handleRemove_Edit(item)"
                  ></Icon>
                </div>
              </template>
            </div>
            <Upload
              v-if="uploadList.length == 0"
              ref="upload"
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="uploadBefore_Edit"
              multiple
              type="drag"
              action
              style="display: inline-block; width: 80px"
            >
              <div
                style="
                  width: 80px;
                  height: 80px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #e0e0e0;
                "
              >
                <img :src="EditUser.head_icon" style="width: 100%" alt="" />
              </div>
            </Upload>
          </FormItem>
          <FormItem label="修改用户名 :" prop="name">
            <Input v-model="EditUser.name"></Input>
          </FormItem>
          <FormItem label="是否修改用户密码 :">
            <RadioGroup
              v-model="changePasswordType"
              @on-change="isChangePassword"
            >
              <Radio :label="1">是</Radio>
              <Radio :label="0">否</Radio>
            </RadioGroup>
          </FormItem>
          <div v-if="changePasswordType === 1">
            <FormItem label="用户原密码 :" prop="OldPassword">
              <Input v-model="EditUser.OldPassword" type="password"></Input>
            </FormItem>
            <FormItem label="新的用户密码 :" prop="NewPassword">
              <Input v-model="EditUser.NewPassword" type="password"></Input>
            </FormItem>
            <FormItem label="再次输入新的密码 :" prop="checkNewPassword">
              <Input
                v-model="EditUser.checkNewPassword"
                type="password"
              ></Input>
            </FormItem>
          </div>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="EditUser_ok"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!-- <Drawer
      width="350"
      placement="left"
      :closable="false"
      :draggable="true"
      :styles="{
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static',
        background: '#1f1f1f',
      }"
      v-model="teamValue"
    >
      <div slot="header" class="drawer-header">
        <span>团队选择</span>
        <div @click="selfTeam" v-if="user_info.permission == 1">回到本团队</div>
      </div>
      <div v-if="user_info.permission == 0">无查看下级团队权限</div>
      <TeamTreeCascader ref="teamTree" v-if="user_info.permission == 1" />
      <div
        class="drawer-btn"
        v-if="user_info.permission == 1"
        @click="clearTeam"
      >
        全选
      </div>
    </Drawer> -->
    <webUpload></webUpload>
  </div>
</template>

<script>
import { upfile, editUserPass, getUserInfo } from '@/utils/javaApi.js'
import TeamListCascader from '@/components/statistics/commonComponents/TeamListCascader.vue'
import webUpload from '@/components/webupload.vue'
import TeamTreeCascader from '@/components/team/teamTreeCascader.vue'
export default {
  name: 'layout',
  components: { TeamListCascader, webUpload, TeamTreeCascader },

  data: function () {
    return {
      logoImg: require('../assets/img/jointflightlogo.png'),
      ZhuZhouJinHang: require('../assets/img/ZhuZhouJinHangLogo.png'), //株洲警航logo
      // Logo:'',
      Logo: require('../assets/img/jointflightlogo.png'),
      police_badge: require('../assets/img/Icon/police_badge.png'),
      UserData: {},
      nav: '海南交警',
      teamShow: true,
      MenuList: [
        {
          name: '日常巡查',
          img: require('../assets/img/Icon/sidebar_project.png'),
          img_press: require('../assets/img/Icon/sidebar_project_press.png'),
          path: '/index/project',
          includes: [
            {
              name: '航线',
              path: '/route-specification',
            },
            {
              name: '创建航线任务',
              path: '/create/air-line/application',
            },
            // {
            //   name: '一级工程列表',
            //   path: '/index/project/ProjectBar_Level_1',
            // },
            {
              name: '二级工程列表',
              path: '/index/project/ProjectBar_Level_2',
            },
            // {
            //   name: '任务列表',
            //   path: '/index/project/ProjectBar_task',
            // },
            {
              name: '任务详情',
              path: '/index/project/TaskBar',
            },
          ],
        },
        {
          name: '实时直播',
          img: require('../assets/img/Icon/sidebar_live.png'),
          img_press: require('../assets/img/Icon/sidebar_live_press.png'),
          path: '/live/live-lists',
        },
        {
          name: '远程指挥',
          img: require('../assets/img/Icon/sidebar_route.png'),
          img_press: require('../assets/img/Icon/sidebar_route_press.png'),
          path: '/live/watch-lists',
        },

        {
          name: '用户管理',
          img: require('../assets/img/Icon/sidebar_team.png'),
          img_press: require('../assets/img/Icon/sidebar_team_press.png'),
          path: '/index/team',
          // children: [
          //   {
          //     name: '用户管理',
          //     path: '/index/team',
          //   },
          // ],
        },
        {
          name: '设备管理',
          path: '/index/equipment',
          img: require('@/assets/img/Icon/sidebar_device.png'),
          img_press: require('@/assets/img/Icon/sidebar_device_press.png'),
          includes: [
            {
              name: '设备管理',
              path: '/index/equipment/UavManage',
            },
            {
              name: '电池管理',
              path: '/index/equipment/BatteryManage',
            },
            {
              name: '使用记录',
              path: '/index/equipment/UsageLog',
            },
            {
              name: '设备保养管理',
              path: '/index/equipment/EquipmentMaintenance',
            },
          ],
        },
        {
          name: '指挥调度',
          img: require('@/assets/img/Icon/sidebar_tra.png'),
          img_press: require('@/assets/img/Icon/sidebar_tra_press.png'),
          path: '/statistics/records',
          children: [
            {
              name: '事件管理与查询',
              path: '/statistics/records',
            },
            {
              name: '任务计划与执行追踪',
              path: '/index/task',
            },
            {
              name: '设置虚拟护栏',
              path: '/index/noFlyZone',
            },
          ],
          includes: [
            {
              name: '轨迹回放',
              path: '/statistics/trajectory-playback',
            },
            {
              name: '执法记录仪回放',
              path: '/statistics/LawPlay',
            },
          ],
        },
        {
          name: '全景采集',
          img: require('../assets/img/statistics/qjzp.png'),
          img_press: require('../assets/img/statistics/qjzp_c.png'),
          path: '/index/panorama',
          includes: [
            {
              name: '全景图详情',
              path: '/index/detailPanorama'
            },
            {
              name: '全景图管理',
              path: '/index/mgrPanorama'
            },
            {
              name: '全景图编辑',
              path: '/index/edtiPanorama'
            },
          ]
          // children: [
          //   {
          //     name: '全景照片',
          //     path: '/index/panorama',
          //     includes: [
          //       { name: '全景图编辑', path: '/index/edtiPanorama' },
          //       { name: '全景图详情', path: '/index/detailPanorama' },
          //       { name: '全景图管理', path: '/index/mgrPanorama' },
          //     ],
          //   },
          // ],
        },
        {
          name: '模型数据',
          path: '/index/resource',
          img: require('../assets/img/Icon/sidebar_model.png'),
          img_press: require('../assets/img/Icon/sidebar_model_press.png'),
          includes: [
            {
              name: '模型详情',
              path: '/modelShou',
            },
            {
              name: '正射详情',
              path: '/orthographicShou',
            },
          ],
        },
        {
          name: '数据储存',
          path: '/index/media',
          img: require('../assets/img/statistics/zpsp.png'),
          img_press: require('../assets/img/statistics/zpsp_c.png'),
        },
        {
          name: '统计分析',
          path: '/statistics/index',
          img: require('../assets/img/Icon/sidebar_sum.png'),
          img_press: require('../assets/img/Icon/sidebar_sum_press.png'),
        },

        // {
        //   name: "设置",
        //   img: require("../assets/img/Icon/sidebar_set.png"),
        //   img_press: require("../assets/img/Icon/sidebar_set_press.png"),
        //   path: "/index/setting"
        // },
        // {
        //   name: "禁飞",
        //   img: require("../assets/img/Icon/noFly.png"),
        //   img_press: require("../assets/img/Icon/noFly_press.png"),
        //   path: "/index/noFlyZone",
        // },
      ],
      changeRed: 0,
      activeChildMenu: 0,
      user_info: {}, //用户数据
      timer: null,
      websockid: 0,
      websock: null,
      isbind: false,
      userData: [], // 存放用户数据的数组
      speakState: '', //远程喊话状态
      chatMsgLists: {
        //聊天
        list: [],
        record: [],
      },
      controlState: '', //远程控制状态
      closeWebsocket: false, //是否关闭Websocket
      userType: '',
      EditUserShou: false,
      EditUser: {
        head_icon: '',
        name: '',
        OldPassword: '',
        NewPassword: '',
        checkNewPassword: '',
      },
      uploadList: [],
      changePasswordType: 0,
      ruleValidate: {
        name: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur',
          },
          {
            message: '用户名长度需为2-12位',
            trigger: 'blur',
            min: 2,
            max: 12,
          },
        ],
      },
      teamValue: false,
    }
  },
  created() {
    this.findActiveTab()
  },
  watch: {
    $route(val) {
      this.findActiveTab()
      if (
        val.name === '任务' ||
        val.name === '全景列表' ||
        val.name === '团队'
      ) {
        this.teamShow = false
      } else {
        this.teamShow = true
      }
    },
  },
  mounted() {
    // var _this = this;
    // 验证用户是否登录
    this.user_info = this.$publicdata.CheckuserLogin()

    this.userType = sessionStorage.getItem('userType')
    this.UserData = this.user_info

    // 飞手路由
    if (this.userType == 2) {
      this.MenuList = [
        {
          name: '日常巡逻',
          img: require('../assets/img/Icon/sidebar_project.png'),
          img_press: require('../assets/img/Icon/sidebar_project_press.png'),
          path: '/index/project',
          includes: [
            {
              name: '航线',
              path: '/route-specification',
            },
            {
              name: '创建航线任务',
              path: '/create/air-line/application',
            },
            {
              name: '一级工程列表',
              path: '/index/project/ProjectBar_Level_1',
            },
            {
              name: '二级工程列表',
              path: '/index/project/ProjectBar_Level_2',
            },
            {
              name: '任务列表',
              path: '/index/project/ProjectBar_task',
            },
            {
              name: '任务详情',
              path: '/index/project/TaskBar',
            },
          ],
        },
        {
          name: '实时直播',
          img: require('../assets/img/Icon/sidebar_live.png'),
          img_press: require('../assets/img/Icon/sidebar_live_press.png'),
          path: '/live/live-lists',
        },
        {
          name: '远程指挥',
          img: require('../assets/img/Icon/sidebar_route.png'),
          img_press: require('../assets/img/Icon/sidebar_route_press.png'),
          path: '/live/watch-lists',
        },
        {
          name: '全景采集',
          img: require('../assets/img/Icon/sidebar_model.png'),
          img_press: require('../assets/img/Icon/sidebar_model_press.png'),
          path: '/index/panorama',
          children: [
            {
              name: '全景照片',
              path: '/index/panorama',
              includes: [
                { name: '全景图编辑', path: '/index/edtiPanorama' },
                { name: '全景图详情', path: '/index/detailPanorama' },
                { name: '全景图管理', path: '/index/mgrPanorama' },
              ],
            },
            {
              name: '模型数据',
              path: '/index/resource',
              includes: [{ name: '正射详情', path: '/orthographicShou' }],
            },
            {
              name: '数据储存',
              path: '/index/media',
            },
          ],
        },

        {
          name: '指挥调度',
          img: require('../assets/img/Icon/sidebar_sum.png'),
          img_press: require('../assets/img/Icon/sidebar_sum_press.png'),
          path: '/statistics/index',
          children: [
            {
              name: '统计分析',
              path: '/statistics/index',
            },
            {
              name: '案件管理与查询',
              path: '/statistics/records',
            },
            {
              name: '任务计划与执行跟踪',
              path: '/index/task',
            },
            {
              name: '设置虚拟护栏',
              path: '/index/noFlyZone',
            },
          ],
          includes: [
            {
              name: '轨迹回放',
              path: '/statistics/trajectory-playback',
            },
            {
              name: '执法记录仪回放',
              path: '/statistics/LawPlay',
            },
            {
              name: '任务详情',
              path: '/index/project/TaskBar',
            },
          ],
        },
      ]
    }
    // this.getUserData();
    // this.MenuList.forEach((ele) => {
    //   if (ele.name == '设备') {
    //     ele.path = '/index/equipment';
    //   }
    //   if (ele.name == '团队') {
    //     ele.path = '/index/team';
    //   }
    //   if (ele.name == '资源') {
    //     ele.path = '/index/resource';
    //   }
    //   if (ele.name == '设置') {
    //     ele.path = '/index/setting';
    //   }
    // });
    this.findActiveTab()

    // // 面包屑
    // this.$_bus.$off('layoutNav');
    // this.$_bus.$on('layoutNav', function (data) {
    //   _this.nav = data;
    // });

    //显示团队id
    this.nav = this.user_info.team_name

    this.EditUser.head_icon = this.user_info.head_icon
    this.EditUser.name = this.user_info.name
  },
  beforeDestroy() {
    // 关闭页面
    if (this.closeWebsocket) {
      console.log(this.closeWebsocket)
      this.websock.close()
      if (this.timer) {
        //停止发送websock数据
        clearInterval(this.timer)
      }
    }
  },
  //   computed: {
  //     isSuperAdmin() {
  //       return (
  //         JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
  //         0
  //       );
  //     },
  //   },
  methods: {
    // 展开团队选择
    showDrawer() {
      if (this.teamShow) {
        this.teamValue = true
      }
    },
    // 清除团队选择
    clearTeam() {
      this.$refs.teamTree.clearUp()
    },
    selfTeam() {
      this.$refs.teamTree.self()
    },
    goPage(item) {
      sessionStorage.setItem('page', 1) // 设置初始翻页
      if (this.$route.path.indexOf(item.path) !== -1) {
        return
      }
      this.$router.push(item.path)
    },
    // 子路由设置聚焦样式
    setActiveMenu(index, item) {
      this.activeChildMenu = parseInt(index)
      this.goPage(item)
    },
    // 根据路由直接定位到tab
    findActiveTab() {
      let activePath = this.$route.path
      let findIndex = (arr, key, target) => {
        for (let i = 0, L = arr.length; i < L; i++) {
          if (arr[i][key] === target) {
            return i
          } else {
            if (arr[i].children) {
              let ret = findIndex(arr[i].children, key, target)
              if (ret !== -1) {
                this.activeChildMenu = ret
                return i
              }
            }
            if (arr[i].includes) {
              let includesRet = findIndex(arr[i].includes, key, target)
              if (includesRet !== -1) {
                this.activeChildMenu = i //3
                return i
              }
            }
          }
        }
        this.activeChildMenu = 0
        return -1
      }
      let ret = findIndex(this.MenuList, 'path', activePath)
      this.changeRed = ret === -1 ? 0 : ret
    },
    // 退出登录
    exit() {
      this.$Modal.confirm({
        title: '退出系统',
        content: '您确定要退出该系统吗？',
        onOk: () => {
          this.$get('/logout', {}).then((res) => {
            localStorage.clear()
            sessionStorage.clear()
            this.$_bus.$emit('closeSocket', true) //关闭Websocket
            if (res.code === 1) {
              this.$Notice.success({
                title: '退出',
                desc: '退出成功',
              })
              this.$router.push({
                path: '/',
              })
            } else {
              this.$Message.error(res.msg_customer)
              this.$router.push({
                path: '/',
              })
            }
          })
        },
        onCancel: () => {},
      })
    },
    // 切换导航高亮区
    MenuSwitchover(item, ind) {
      console.log(ind)
      this.activeChildMenu = 0
      this.changeRed = ind
      this.goPage(item)
    },
    // getData() {
    //   //绑定websocket
    //   this.$post('/base/common/bindClient', {
    //     client: 'web',
    //     client_id: this.websockid,
    //   }).then((res) => {
    //     if (res.code == 1) {
    //       this.isbind = true;
    //     } else {
    //       this.isbind = false;
    //     }
    //   });
    // },
    // 新增用户的头像上传
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择jpg或png.',
      })
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于2M.',
      })
    },
    // 重置此头像
    handleRemove(item) {
      console.log('点击了删除')
      this.uploadList.splice(this.uploadList.indexOf(item), 1)
    },
    // 编辑弹框的头像上传=====================
    //上传用户头像
    uploadBefore_Edit(file) {
      if (file) {
        const url = upfile()
        let fm = new FormData()
        fm.append('client', 'web')
        fm.append('file', file)
        fm.append('teamId', this.user_info.team_id)
        this.$fromdata(url, fm).then((res) => {
          if (res.code == 1) {
            this.EditUser.head_icon = res.data.path + res.data.head_icon
            this.uploadList.push({
              status: 'finished',
              url: res.data.head_icon,
              path: res.data.path,
            })
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      }
    },
    // 重置此头像
    handleRemove_Edit(item) {
      console.log('点击了删除')
      this.EditUser.head_icon = ''
      this.uploadList.splice(this.uploadList.indexOf(item), 1)
    },
    // 切换是否修改密码
    isChangePassword() {
      if (this.changePasswordType) {
        this.ruleValidate = {
          name: [
            {
              required: true,
              message: '用户名不能为空',
              trigger: 'blur',
            },
            {
              message: '用户名长度需为2-12位',
              trigger: 'blur',
              min: 2,
              max: 12,
            },
          ],
          OldPassword: [
            {
              required: true,
              message: '原密码不能为空',
              trigger: 'blur',
            },
            {
              message: '密码长度需为8-20位',
              min: 8,
              max: 20,
            },
          ],
          NewPassword: [
            {
              required: true,
              message: '新密码不能为空',
              trigger: 'blur',
            },
            {
              message: '密码长度需为8-20位',
              min: 8,
              max: 20,
            },
          ],
          checkNewPassword: [
            {
              required: true,
              message: '验证密码不能为空',
              trigger: 'blur',
            },
            {
              message: '密码长度需为8-20位',
              min: 8,
              max: 20,
            },
          ],
        }
      } else {
        this.ruleValidate = {
          name: [
            {
              required: true,
              message: '用户名不能为空',
              trigger: 'blur',
            },
            {
              message: '用户名长度需为2-12位',
              trigger: 'blur',
              min: 2,
              max: 12,
            },
          ],
        }
        this.EditUser.OldPassword = ''
        this.EditUser.NewPassword = ''
        this.EditUser.checkNewPassword = ''
      }
    },
    // 提交编辑用户
    EditUser_ok() {
      let data = {
        client: 'web',
        name: this.EditUser.name,
        user_id: Number(this.user_info.user_id),
        head_icon: this.uploadList.length === 0 ? null : this.uploadList[0].url,
        isModifyPasswd: this.changePasswordType,
      }
      console.log(data)
      if (data.isModifyPasswd == 1) {
        data.old_passwd = this.EditUser.OldPassword
        data.new_passwd = this.EditUser.NewPassword
        data.check_pass = this.EditUser.checkNewPassword
        if (!data.old_passwd || !data.new_passwd || !data.check_pass) {
          this.$Message.error('密码未输入')
          return
        }
        if (data.new_passwd !== data.check_pass) {
          this.$Message.error('验证密码必须与新密码一致')
          return
        }
        data.old_passwd = this.$zxh_fun.encrypt(this.EditUser.OldPassword)
        data.new_passwd = this.$zxh_fun.encrypt(this.EditUser.NewPassword)
        data.check_pass = this.$zxh_fun.encrypt(this.EditUser.checkNewPassword)
      }
      this.$refs['EditUser'].validate((valid) => {
        if (valid) {
          this.$post(editUserPass(), data)
            .then((res) => {
              if (res.code == 1) {
                this.$Message.info('修改成功')
                this.getUserData()
                this.EditUserShou = false
                this.uploadList = []
                this.EditUser.head_icon = ''
                this.OldPassword = ''
                this.NewPassword = ''
                this.checkNewPassword = ''
                this.$refs['EditUser'].resetFields()
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
            .catch((err) => {
              //   this.uploadList.splice(this.uploadList.indexOf(item), 1);
              this.uploadList = []

              this.EditUser.head_icon = ''
            })
        }
      })
    },
    // 用户编辑弹窗关闭时
    closeEditUser() {
      this.$refs['EditUser'].resetFields()
      this.EditUser.name = this.user_info.name
      this.EditUser.head_icon = this.user_info.head_icon
      this.uploadList = []
      this.EditUser.OldPassword = ''
      this.EditUser.NewPassword = ''
    },
    // 获取登录用户信息
    getUserData() {
      this.$post(getUserInfo()).then((res) => {
        if (res.code == 1) {
          this.UserData = res.data
          this.EditUser.name = this.UserData.name
          this.EditUser.head_icon = this.UserData.head_icon
          sessionStorage.setItem('user_info', JSON.stringify(res.data))
        } else {
          this.isbind = false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
#layout {
  .header {
    /deep/.ivu-breadcrumb {
      a {
        color: #333;
        font-size: 18px;
      }
    }
  }
}
.layout {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .header {
    position: relative;
    flex: 0 0 50px;
    width: 100%;
    background-color: #383838;
    color: #fff;
    padding: 0 20px 0 14px;
    display: flex;
    justify-content: center;
    border-bottom: 1px #000 solid;
    .header-left {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // img {
      //   height: 30px;
      //   width: auto;
      // }
      // .left-system-name {
      //   font-weight: 700;
      //   // color: rgb(81, 90, 110);
      //   color: #fff;
      //   font-size: 16px;
      //   white-space: nowrap;
      // }
      // .divide {
      //   width: 1px;
      //   height: 30px;
      //   background-color: #ccc;
      //   margin: 0 14px;
      //   line-height: 50px;
      // }
      // .left-team-name {
      //   font-weight: 700;
      //   // color: #515a6e;
      //   color: #fff;
      //   font-size: 14px;
      //   white-space: nowrap;
      //   margin-right: 14px;
      // }
      .teamList {
        position: absolute;
        left: 10px;
        top: 17.5px;
        width: 200px;
      }
      /deep/ .ivu-input {
        background: #383838;
        color: #fff;
      }
      /deep/ .ivu-select-dropdown {
        background: #383838;
        width: 100%;
      }
      /deep/ .ivu-cascader-menu {
        width: 100%;
        background: #383838;
      }
      /deep/.ivu-cascader-menu-item {
        color: #fff;
      }
      /deep/.ivu-cascader-menu .ivu-cascader-menu-item-active {
        color: #000;
      }
      /deep/.ivu-cascader-menu-item:hover {
        color: #000;
      }
      /deep/ .ivu-cascader-menu::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      /deep/ .ivu-cascader-menu::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 4px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #929292;
      }
      /deep/.ivu-cascader-menu::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background: #ededed;
      }
      .header-policeBadge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 30px;
        background: #383838;
        color: #fff;
        padding: 10px 0;
        border-bottom: 1px #000 solid;
        .header-policeBadge-img {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }
      }
    }
    .header-right {
      position: absolute;
      right: 30px;
      top: 15.5px;
      display: flex;
      align-items: center;
      .headImg {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        > img {
          width: auto;
          height: 100%;
          cursor: pointer;
        }
      }
      span {
        font-size: 14px;
        color: #fff;
        margin-right: 20px;
      }
      .header-right-exit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;
        height: 23px;
        background: #023055;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
      .header-right-exit:hover {
        background-color: #5d5d5d;
      }
    }
  }
  .layoutSidebar {
    flex: 1;
    display: flex;
    // overflow: hidden;
    align-items: stretch;
    background-color: #313131;
    .team-cascader {
      border-right: 1px solid #000;
      width: 30px;
      height: 100%;
      cursor: pointer;
      > img {
        display: block;
        margin: 23px auto 0;
      }
    }
    .team-cascader:hover {
      background: #56585e;
    }
    .sidebar {
      flex: 0 0 70px;
      background-color: #313131;
      color: #fff;
      padding-top: 20px;
      border-right: 1px solid #000;
      // z-index: 100000 !important;
      > div {
        width: 100%;
        height: 72px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        > div {
          width: 58px;
          //   height: 38px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item {
          img {
            width: 22px;
            height: auto;
          }
          span {
            color: #fff;
            font-size: 12px;
            margin-top: 4px;
            display: block;
            text-align: center;
          }
        }

        &:hover {
          .bar-child {
            display: inline-flex;
            z-index: 3000;
          }
        }
        .bar-child {
          z-index: -2;
          // opacity: 0;
          display: none;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          background-color: #fff;
          padding: 5px 5px 0;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          left: 78px;
          transition: all 0.3s ease-in;
          border-radius: 4px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          cursor: pointer;
          div {
            width: 100%;
            padding: 0 5px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #707070;
            white-space: nowrap;
            border-bottom: 1px solid rgba(112, 112, 112, 1);
            &:last-child {
              border-bottom: none;
            }
          }
          .active {
            color: #409eff;
          }
          &::before {
            content: '';
            width: 62px;
            height: 72px;
            position: absolute;
            left: -40px;
            top: 0;
          }
        }
      }
      .select {
        background-color: #000;
        border-left: 7px solid #2196f3;
        .item {
          span {
            color: #2196f3;
            font-size: 12px;
          }
        }
      }
    }
    .Content {
      height: 100%;
      flex: 1;
      overflow: hidden;
    }
    .Content::-webkit-scrollbar {
      display: none;
    }
  }
}
/deep/.EditUser-modal {
  .upload-list {
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 3px 6px #eee;
    margin-right: 4px;
  }
  .upload-list img {
    width: 100%;
    height: 100%;
  }
  .upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .upload-list:hover .upload-list-cover {
    display: block;
  }
  .upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
}
/deep/.ivu-poptip-popper {
  padding-left: 55px;
}
/deep/ .ivu-drawer-header {
  background: #1f1f1f;
  border-bottom: 1px solid #000000;
}
.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c9caca;
  // background: #fafafa;
  > div {
    // width: 41px;
    padding: 0 5px;
    height: 25px;
    background: #4a4a4a;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
  }
  > div:hover {
    background: #252525;
  }
}
.drawer-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid #000;
  background: #fafafa;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.drawer-btn:hover {
  background: #4a4a4a;
}
</style>
