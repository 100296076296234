<template>
  <div id="TeamListCascader">
    <Cascader
      :data="TeamList"
      :load-data="loadChildren"
      :render-format="format"
      :disabled="user_info.permission == 0"
      change-on-select
      :placeholder="user_info.permission ? '请选择团队' : '无查看下级团队权限'"
      @on-change="change"
      @on-visible-change="visibleChange"
      :value="v"
    ></Cascader>
  </div>
</template>

<script>
import { ChildTeamList } from '@/utils/javaApi.js'
export default {
  data() {
    return {
      TeamList: [],
      v: [],
      user_info: '',
    }
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    this.userType = sessionStorage.getItem('userType')
    this.loadTeamList(this.user_info.team_id)
    this.$_bus.$off('team_refresh').$on('team_refresh', () => {
      this.loadTeamList(this.user_info.team_id)
    })
  },
  methods: {
    //加载团队数据
    loadTeamList(id) {
      this.$post(ChildTeamList(), {
        team_id: id,
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          //   this.TeamList = res.data;
          //   this.TeamList.map((el) => {
          //     el.label = el.team_name;
          //   });
          if (res.data) {
            res.data.forEach((ele) => {
              //   ele.value = ele.id;
              //   delete ele.id;
              //   ele.label = ele.team_name;
              if (ele.children === null) {
                delete ele.children
              } else {
                ele.loading = false
              }
            })
            // this.TeamList = res.data.filter((e) => e.value !== id);
            this.TeamList = res.data
            this.TeamList.unshift({
              // children:[],
              label: this.user_info.team_name,
              // loading:false,
              value: this.user_info.team_id,
            })
          } else {
            this.TeamList = [
              {
                // children:[],
                label: this.user_info.team_name,
                // loading:false,
                value: this.user_info.team_id,
              },
            ]
          }
          // this.v = [this.$store.state.unit_id];
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //加载子团队数据
    loadChildren(item, callback) {
      item.loading = true
      this.$post(ChildTeamList(), {
        team_id: item.value,
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          res.data.forEach((ele) => {
            // ele.value = ele.id;
            // delete ele.id;
            // ele.label = ele.team_name;
            if (ele.children === null) {
              delete ele.children
            } else {
              ele.loading = false
            }
          })
          item.children = res.data
          item.loading = false
          callback()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    visibleChange(value, selectedData) {
      //   this.$emit('visible_Team', value, selectedData);
    },
    change(value) {
      if (value.length === 0) {
        // value = [JSON.parse(sessionStorage.getItem("user_info")).team_id];
        value = [-1]
      }
      // this.$store.commit('saveUnit',value[0])
      this.$_bus.$emit('change_Team', value[value.length - 1])
      sessionStorage.setItem('team_id', value[value.length - 1])
    },
    format(labels) {
      const index = labels.length - 1
      return labels[index]
    },
    clear() {
      this.v = []
    },
  },
}
</script>

<style scoped lang="scss">
#TeamListCascader {
  // width: 100%;
  z-index: 900;
}
</style>
